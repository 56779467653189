@import url(https://fonts.googleapis.com/css?family=Questrial);
body {
  --green: #4ecdc4;
  --jet: #333;
  --red: #f72c25;
  --dark-red: hsl(2, 72%, 45%);
  --black: #0d1b1e;
  color: white;
  margin: 0;
  padding: 0;
  font-family: 'Questrial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; }

#root {
  height: 100%; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.app {
  background: var(--black);
  height: 100%;
  display: flex;
  flex-direction: column; }

.header {
  background: var(--jet);
  height: 35px; }
  .header nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0 10px; }
  .header a {
    color: white;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease;
    padding: 0 10px; }
    .header a.active {
      color: var(--dark-red); }
    .header a:hover {
      color: var(--red); }

.content {
  height: calc(100vh - 35px - 30px); }

.footer {
  background: var(--jet);
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around; }
  .footer a {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    color: white; }

.home-container {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  .home-container h1 a {
    color: var(--red); }
  .home-container a img {
    width: 200px; }

.banter-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 72px;
  height: 72px;
  margin-left: -36px;
  margin-top: -36px; }
  .banter-loader__box {
    float: left;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 6px; }
    .banter-loader__box:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff; }
    .banter-loader__box:nth-child(3n) {
      margin-right: 0;
      margin-bottom: 6px; }
    .banter-loader__box:nth-child(1):before, .banter-loader__box:nth-child(4):before {
      margin-left: 26px; }
    .banter-loader__box:nth-child(3):before {
      margin-top: 52px; }
    .banter-loader__box:last-child {
      margin-bottom: 0; }

@-webkit-keyframes moveBox-1 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(0px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 26px); }
  54.54545% {
    transform: translate(26px, 26px); }
  63.63636% {
    transform: translate(26px, 26px); }
  72.72727% {
    transform: translate(26px, 0px); }
  81.81818% {
    transform: translate(0px, 0px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-1 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(0px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 26px); }
  54.54545% {
    transform: translate(26px, 26px); }
  63.63636% {
    transform: translate(26px, 26px); }
  72.72727% {
    transform: translate(26px, 0px); }
  81.81818% {
    transform: translate(0px, 0px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(1) {
  -webkit-animation: moveBox-1 4s infinite;
          animation: moveBox-1 4s infinite; }

@-webkit-keyframes moveBox-2 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 26px); }
  54.54545% {
    transform: translate(26px, 26px); }
  63.63636% {
    transform: translate(26px, 26px); }
  72.72727% {
    transform: translate(26px, 26px); }
  81.81818% {
    transform: translate(0px, 26px); }
  90.90909% {
    transform: translate(0px, 26px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-2 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 26px); }
  54.54545% {
    transform: translate(26px, 26px); }
  63.63636% {
    transform: translate(26px, 26px); }
  72.72727% {
    transform: translate(26px, 26px); }
  81.81818% {
    transform: translate(0px, 26px); }
  90.90909% {
    transform: translate(0px, 26px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(2) {
  -webkit-animation: moveBox-2 4s infinite;
          animation: moveBox-2 4s infinite; }

@-webkit-keyframes moveBox-3 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(-26px, 0); }
  45.45455% {
    transform: translate(-26px, 0); }
  54.54545% {
    transform: translate(-26px, 0); }
  63.63636% {
    transform: translate(-26px, 0); }
  72.72727% {
    transform: translate(-26px, 0); }
  81.81818% {
    transform: translate(-26px, -26px); }
  90.90909% {
    transform: translate(0px, -26px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-3 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(-26px, 0); }
  45.45455% {
    transform: translate(-26px, 0); }
  54.54545% {
    transform: translate(-26px, 0); }
  63.63636% {
    transform: translate(-26px, 0); }
  72.72727% {
    transform: translate(-26px, 0); }
  81.81818% {
    transform: translate(-26px, -26px); }
  90.90909% {
    transform: translate(0px, -26px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(3) {
  -webkit-animation: moveBox-3 4s infinite;
          animation: moveBox-3 4s infinite; }

@-webkit-keyframes moveBox-4 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, -26px); }
  36.36364% {
    transform: translate(0px, -26px); }
  45.45455% {
    transform: translate(0px, 0px); }
  54.54545% {
    transform: translate(0px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(-26px, -26px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-4 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, -26px); }
  36.36364% {
    transform: translate(0px, -26px); }
  45.45455% {
    transform: translate(0px, 0px); }
  54.54545% {
    transform: translate(0px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(-26px, -26px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(4) {
  -webkit-animation: moveBox-4 4s infinite;
          animation: moveBox-4 4s infinite; }

@-webkit-keyframes moveBox-5 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(0, 0); }
  27.27273% {
    transform: translate(0, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 0); }
  54.54545% {
    transform: translate(26px, 0); }
  63.63636% {
    transform: translate(26px, 0); }
  72.72727% {
    transform: translate(26px, 0); }
  81.81818% {
    transform: translate(26px, -26px); }
  90.90909% {
    transform: translate(0px, -26px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-5 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(0, 0); }
  27.27273% {
    transform: translate(0, 0); }
  36.36364% {
    transform: translate(26px, 0); }
  45.45455% {
    transform: translate(26px, 0); }
  54.54545% {
    transform: translate(26px, 0); }
  63.63636% {
    transform: translate(26px, 0); }
  72.72727% {
    transform: translate(26px, 0); }
  81.81818% {
    transform: translate(26px, -26px); }
  90.90909% {
    transform: translate(0px, -26px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(5) {
  -webkit-animation: moveBox-5 4s infinite;
          animation: moveBox-5 4s infinite; }

@-webkit-keyframes moveBox-6 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, 0); }
  36.36364% {
    transform: translate(0px, 0); }
  45.45455% {
    transform: translate(0px, 0); }
  54.54545% {
    transform: translate(0px, 0); }
  63.63636% {
    transform: translate(0px, 0); }
  72.72727% {
    transform: translate(0px, 26px); }
  81.81818% {
    transform: translate(-26px, 26px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-6 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, 0); }
  36.36364% {
    transform: translate(0px, 0); }
  45.45455% {
    transform: translate(0px, 0); }
  54.54545% {
    transform: translate(0px, 0); }
  63.63636% {
    transform: translate(0px, 0); }
  72.72727% {
    transform: translate(0px, 26px); }
  81.81818% {
    transform: translate(-26px, 26px); }
  90.90909% {
    transform: translate(-26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(6) {
  -webkit-animation: moveBox-6 4s infinite;
          animation: moveBox-6 4s infinite; }

@-webkit-keyframes moveBox-7 {
  9.09091% {
    transform: translate(26px, 0); }
  18.18182% {
    transform: translate(26px, 0); }
  27.27273% {
    transform: translate(26px, 0); }
  36.36364% {
    transform: translate(0px, 0); }
  45.45455% {
    transform: translate(0px, -26px); }
  54.54545% {
    transform: translate(26px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(0px, 0px); }
  90.90909% {
    transform: translate(26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-7 {
  9.09091% {
    transform: translate(26px, 0); }
  18.18182% {
    transform: translate(26px, 0); }
  27.27273% {
    transform: translate(26px, 0); }
  36.36364% {
    transform: translate(0px, 0); }
  45.45455% {
    transform: translate(0px, -26px); }
  54.54545% {
    transform: translate(26px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(0px, 0px); }
  90.90909% {
    transform: translate(26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(7) {
  -webkit-animation: moveBox-7 4s infinite;
          animation: moveBox-7 4s infinite; }

@-webkit-keyframes moveBox-8 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, -26px); }
  36.36364% {
    transform: translate(0px, -26px); }
  45.45455% {
    transform: translate(0px, -26px); }
  54.54545% {
    transform: translate(0px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(26px, -26px); }
  90.90909% {
    transform: translate(26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

@keyframes moveBox-8 {
  9.09091% {
    transform: translate(0, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(-26px, -26px); }
  36.36364% {
    transform: translate(0px, -26px); }
  45.45455% {
    transform: translate(0px, -26px); }
  54.54545% {
    transform: translate(0px, -26px); }
  63.63636% {
    transform: translate(0px, -26px); }
  72.72727% {
    transform: translate(0px, -26px); }
  81.81818% {
    transform: translate(26px, -26px); }
  90.90909% {
    transform: translate(26px, 0px); }
  100% {
    transform: translate(0px, 0px); } }

.banter-loader__box:nth-child(8) {
  -webkit-animation: moveBox-8 4s infinite;
          animation: moveBox-8 4s infinite; }

@-webkit-keyframes moveBox-9 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(-26px, 0); }
  45.45455% {
    transform: translate(0px, 0); }
  54.54545% {
    transform: translate(0px, 0); }
  63.63636% {
    transform: translate(-26px, 0); }
  72.72727% {
    transform: translate(-26px, 0); }
  81.81818% {
    transform: translate(-52px, 0); }
  90.90909% {
    transform: translate(-26px, 0); }
  100% {
    transform: translate(0px, 0); } }

@keyframes moveBox-9 {
  9.09091% {
    transform: translate(-26px, 0); }
  18.18182% {
    transform: translate(-26px, 0); }
  27.27273% {
    transform: translate(0px, 0); }
  36.36364% {
    transform: translate(-26px, 0); }
  45.45455% {
    transform: translate(0px, 0); }
  54.54545% {
    transform: translate(0px, 0); }
  63.63636% {
    transform: translate(-26px, 0); }
  72.72727% {
    transform: translate(-26px, 0); }
  81.81818% {
    transform: translate(-52px, 0); }
  90.90909% {
    transform: translate(-26px, 0); }
  100% {
    transform: translate(0px, 0); } }

.banter-loader__box:nth-child(9) {
  -webkit-animation: moveBox-9 4s infinite;
          animation: moveBox-9 4s infinite; }

.top-container {
  height: 100%;
  display: flex;
  position: relative; }
  .top-container .top-list {
    margin: 0 auto;
    max-width: 600px; }

.loader-hidden {
  display: none; }

.loader-active {
  height: 100px;
  display: block; }

.post {
  padding: 20px;
  border: 2px solid var(--dark-red);
  margin: 10px 20px;
  border-radius: 14px; }
  .post-author {
    font-size: 0.75em;
    opacity: 0.65; }
  .post-time-and-eth {
    display: flex;
    justify-content: space-between;
    font-size: 0.75em; }
    .post-time-and-eth .time {
      opacity: 0.65; }
    .post-time-and-eth .eth {
      color: var(--green);
      font-weight: bold;
      font-size: 0.85rem; }
      .post-time-and-eth .eth::after {
        content: " ETH "; }
  .post-content {
    margin: 10px 0; }
    .post-content a {
      color: var(--red); }

.send-container .send {
  color: var(--red);
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: 600px;
  text-align: center; }
  .send-container .send input {
    background: transparent;
    color: var(--red);
    border: 1px solid var(--jet);
    border-radius: 8px;
    padding: 20px; }
    .send-container .send input:focus {
      outline: none;
      border-color: var(--dark-red); }
  .send-container .send textarea.text {
    resize: none; }
  .send-container .send input.eth {
    text-align: center; }
  .send-container .send input[type='number']::-webkit-inner-spin-button,
  .send-container .send input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .send-container .send button {
    background: var(--red);
    color: black;
    font-weight: 600;
    border: 2px solid black;
    border-radius: 8px;
    padding: 25px;
    max-width: 300px;
    margin: 20px auto; }
    .send-container .send button:hover {
      border-color: var(--red);
      color: var(--red);
      background: transparent;
      cursor: crosshair; }

