.home-container {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: space-around; 
    h1 {
        a {
            color: var(--red);
        }
    }
    a {
        img {
            width: 200px;
        }
    }
}