.app {
  background: var(--black);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  background: var(--jet);
  height: 35px;
  nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }
  a {
    color: white;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease;
    padding: 0 10px;
    &.active {
      color: var(--dark-red);
    }
    &:hover {
      color: var(--red);
    }
  }
}
.content {
  height: calc(100vh - 35px - 30px);
}
.footer {
  background: var(--jet);
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
    color: white;
  }
}
