@import url('https://fonts.googleapis.com/css?family=Questrial');
body {
  --green: #4ecdc4;
  --jet: #333;
  --red: #f72c25;
  --dark-red: hsl(2, 72%, 45%);
  --black: #0d1b1e;
  color: white;
  margin: 0;
  padding: 0;
  font-family: 'Questrial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
